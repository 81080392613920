.post_textbox {
    border: solid;
    margin-bottom: 28px;
}

.post_textbox:focus {
    border: solid !important;
    margin-bottom: 28px;
}

.FbPostMessageContainer {
    margin-top: 21px;
}

.facebookPostButton {
    width: 16%;
    height: 38px;
    border-radius: 9px;
    margin-bottom: 20px;
    line-height: 1.42857143 !important;
    margin-left: 40px;
    background: #272727;
    font-weight: bolder;
    padding: 2px;
    margin-left: 125px;
}

.facebookLiveButton {
    width: 16%;
    height: 38px;
    border-radius: 9px;
    margin-bottom: 20px;
    line-height: 1.42857143 !important;
    margin-left: 40px;
    background: #4080ff;
    font-weight: bolder;
    padding: 2px;
    margin-left: 125px;
}

.LiveVideoDisplayContainer {
    width: 90%;
    border: solid;
    height: 500px;
}

.LiveVideoCommentsContainer {
    margin-top: 15px;
    width: 90%;
    border: solid;
    height: 200px;
}