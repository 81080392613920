.form_container {
    border: solid;
    padding: 20px;
}

.formTextBox {
    width: 43%;
    padding: 7px;
    margin: 5px 0 16px 0px;
    display: inline-block;
    border: none;
    background: #f1f1f1;
}

.sketch-picker {
    margin-left: 310px;
}

.colorPickerContainer {
    margin-bottom: 7px;
}

.krsubmitbutton {
    margin-top: 10px;
    width: 15%;
    padding: 11px;
    border-radius: 5px;
    margin-left: 450px;
}