.correct_icon_status {
    margin-left: 135px;
    color: green;
}

.wrong_icon_status {
    margin-left: 135px;
    color: red;
}

.status_container_alignment_custom1 {
    margin-bottom: 3px;
    padding: 3px;
}

.custom_hr_tag {
    width: 93%;
}

.refresh-link-button-custom-css {
    background-color: transparent;
    border-radius: 5px;
    color: black !important;
    padding: 4px 8px;
    width: auto;
    margin: 5px;
    outline: none;
    margin-left: 25px;
}

.move_app_links {
    margin-left: 135px !important;
}

.open-link-button-custom-css {
    background-color: transparent;
    border-radius: 5px;
    color: black;
    padding: 4px 8px;
    width: auto;
    margin: 5px;
    outline: none;
}

.vajro-plan-status {
    margin-left: 24px;
}

.app-link-button {
    width: 13%;
    padding: 5px;
    margin: 5px;
}

.crash-button-custom-css {
    background-color: #272727;
    border-radius: 5px;
    color: white;
    padding: 5px;
    width: 8%;
    margin: 5px;
    outline: none;
}