.App {
    text-align: center;
}

.copy_icon_button {
    background-color: #272727;
    border-radius: 5px;
    color: white;
    padding: 12px 18px;
    width: auto;
    margin: 5px;
    outline: none;
}

textarea {
    resize: none;
}

.textareaScrollbarDesc {
    overflow-y: scroll !important;
}

.react-autosuggest__input {
    margin-left: 15px;
    width: 371px !important;
}

#sidebar ul li a:hover {
    color: white;
    background: #4444;
}

.external_link_button,
.download_link_button {
    background-color: #272727;
    border-radius: 5px;
    color: white;
    padding: 12px 18px;
    width: auto;
    margin: 5px;
    outline: none;
}

.refresh_icon_button {
    background-color: #272727;
    border-radius: 5px;
    color: white;
    padding: 6px;
    width: 7%;
    height: 0%;
    margin: 3px;
    outline: none;
    margin-top: -2px;
    margin-left: 6px
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.suggestion-box {
    /* display: flex; */
    flex: 0 1 auto;
}

.logintab {
    font-size: 16px;
    text-align: center;
    padding: 0px;
}

.login_text {
    text_align: center;
}

.login_page {
    text-align: center;
}

.refresh_button {
    border-radius: 6px;
    font-size: initial;
    width: 14%;
    margin-bottom: 20px;
    line-height: 1.42857143 !important;
    background: #272727;
    height: 39px;
    font-style: normal;
}

.save_button {
    width: 16%;
    height: 38px;
    border-radius: 9px;
    margin-bottom: 20px;
    line-height: 1.42857143 !important;
    margin-left: 40px;
    background: #272727;
    font-weight: bolder;
}

.anchor_link {
    color: #4298f4;
}

.displayAppLinksBox {
    border: 2px dashed #ccc;
    display: table;
    width: 100%;
    padding: 10px;
}

.labelAppLink {
    margin: 20px;
}

.accountpage_full {
    width: calc(100% - 250px);
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

.dropdown-custom-2 {
    margin-left: -40px !important;
}

.labelinfo {
    padding: 15px;
}

.passwd_forget_form {
    text-align: center;
}

.vajro_homepage {
    font-style: italic;
    font-family: initial;
    font-weight: bold;
}

.sweet-loading {
    height: 100vh;
    align-items: center;
    margin: auto;
    display: flex;
}

.vajro_font_placement {
    width: 100%;
    margin-top: -120px;
    font-family: 'Kaushan Script', cursive;
    font-size: 54px;
    color: #ff4747;
    padding: 4px;
}

.vajro_font {
    font-family: 'Kaushan Script', cursive;
    font-size: 45px;
    color: #ff4747;
    /* font-style: italic; */
    /* font-weight: bold; */
    padding: 3px;
    margin-left: 29px;
}


/* input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=password] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

div {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
} */


/* input[type=text],
input[type=password] {
    width: 43%;
    padding: 15px;
    margin: 5px 0 22px 0px;
    display: inline-block;
    border: none;
    background: #f1f1f1;
} */

.nav-tabs>li.active>a {
    font-family: Montserrat-Bold;
}

.button-default,
.pass-button-default {
    width: 43%;
    padding: 15px;
    margin: 5px 0 22px 0px;
    display: inline-block;
    border: none;
    background: #f1f1f1;
}

input[type=text]:focus,
input[type=password]:focus {
    background-color: #ddd;
    outline: none;
}

hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
}


/* Set a style for all buttons */

button {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 5px 0 22px 0px;
    border: none;
    cursor: pointer;
    width: 43%;
    opacity: 0.9;
}

button:hover {
    opacity: 1;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.showAppInfo {
    padding: 7px 16px;
    vertical-align: middle;
    line-height: 3;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#uncontrolled-tab-example .tab-content {
    border: 1px solid #ddd;
    border-top: none;
    padding: 10px;
}

.panel-group {
    margin-bottom: 5px !important;
}


/*switch*/

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 22px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #272727;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/*home page input group */

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.add_app_id_textbox {
    width: 50%;
}

.input_appinfo_applogo {
    border-radius: 5px;
    width: 61%;
    padding: 14px;
    color: rgb(11, 126, 235);
    border: groove;
}

.panel-title a {
    font-family: Montserrat-Bold;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    /* font-weight: bold; */
    color: white;
    /* font-family: Montserrat-Bold; */
    cursor: pointer;
}

#sidebar {
    background: #272727;
}

.sidebar-header {
    background: #0e0e0ee3;
}

.button-hover {
    color: white !important;
    /* text-decoration: none; */
    outline-offset: -5px;
    cursor: pointer;
}

.appidInputBox,
.appidInputBox:focus {
    border: solid !important;
    padding: 1px;
}

.applyAppidButton {
    width: 10%;
    height: 28px;
    border-radius: 9px;
    background: #272727;
    font-weight: bolder;
    padding: 2px;
    position: absolute;
    margin: 0px;
    margin-left: 23px;
}

.applyAppidContainer {
    width: 50%;
    float: right;
}