.Pane1,
.Pane2 {
    flex: 0 0 auto;
    position: relative;
    outline: none;
    width: 50% !important;
    border: dashed;
    margin: 10px;
    overflow-y: scroll;
    background: lightgray;
}

.task-container {
    width: 125%;
}

.platform_badge_custom1 {
    position: absolute;
}

.vertical_pane {
    height: 800px;
    overflow-y: scroll;
    margin-top: 73px;
    margin-bottom: 10px;
    width: 19%;
    padding: 5px;
    border-radius: 5px;
    overflow-x: scroll;
    margin-left: 4px;
    background: gainsboro;
}

.priority_bg_color {
    background: #e7b28c !important;
}

#overlay {
    position: fixed;
    /* Sit on top of the page content */
    display: none;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
}

#taskFormButton {
    width: 16%;
    border-radius: 9px;
    margin-bottom: 20px;
    line-height: 1.42857143 !important;
    margin-left: 40px;
    background: #272727;
    font-weight: bolder;
}

.card_label_container {
    font-size: 13px;
}

.card {
    margin: 6px !important;
    padding: 4px !important;
}

.taskTextBox {
    width: 43%;
    padding: 15px;
    margin: 5px 0 22px 0px;
    display: inline-block;
    border: none;
    background: #f1f1f1;
}

.task_button_alignment {
    padding: 120px;
}

.para-card-reason {
    font-size: small;
    margin-left: -2px;
    margin-bottom: 5px;
}

#task_form {
    margin: auto;
    width: 60%;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: solid;
}

.SplitPane {
    margin-top: 51px;
}

.platform_badge {
    float: right;
    position: relative;
    margin: 9px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.since_created_time_badge {
    /* margin-left: -3px !important; */
    color: white;
    background: crimson;
    margin: 9px;
    margin-right: 0px !important;
    font-weight: 400 !important;
    margin-left: 0px !important;
}

.since_created_time_badge_change_color {
    color: white;
    background: orange;
    margin: 7px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-weight: 400 !important;
}

h1 {
    text-align: center;
    padding: 3px;
    margin-top: 6px;
    font-size: 21px;
}

.platform_badge_custom {
    margin: -9px;
}

.fa-times-circle {
    color: burlywood;
}

.task_delete_button {
    background: transparent;
    padding: 4px;
    width: 28px;
    border-radius: 4px;
}

.create_task_icon {
    float: right;
    padding: 12px 18px;
    position: absolute;
    background-color: black;
    margin: 5px;
    width: auto;
    display: none;
    border-radius: 9px;
    cursor: pointer;
}

.divspacing {
    margin-top: 13px;
}

.card_reason_textarea {
    width: 230px;
    border-radius: 4px;
}

textarea {
    padding: 3px;
    border-radius: 10px;
    margin-left: 8px;
    border: none;
    overflow: hidden;
    font-size: small;
    color: #666;
}

#dropdown-custom-1 {
    width: 100%;
    margin-top: 1px;
    margin-left: -2px;
    margin-bottom: 0px;
}

.card {
    margin: 10px;
    padding: 5px;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 4px #666;
}

.div_delete_button {
    float: right;
    margin-top: -13px;
    margin-right: -13px;
}

.card-body {
    margin: 5px;
    padding: 5px;
    background: red;
    border-radius: 15px;
}

.card-title {
    /* text-align: center; */
    margin-bottom: 5px;
    font: bold;
    font-size: 17px;
    margin-left: -5px;
    margin-top: -7px;
}

.card-inner-header {
    padding: 2px;
}

.badge {
    margin-left: 15px;
    margin-bottom: 1px;
}

.badge-card {
    padding: 5px;
    margin: 3px;
}

.pending_task_card_css {
    background: transparent !important;
}

.onHold_task_card_css {
    background: transparent !important;
}

.intercom_css {
    background: transparent;
    margin-top: 4px;
    max-width: 23px;
    max-height: 0%;
    padding: 2px;
    margin: 3px;
    border-radius: 3px;
    font-size: 11px;
    margin-bottom: 0px;
}

.intercom_image_width {
    width: 17px;
}